
const CardData =[
    {
        id:1,
        eventName:"Dubai | USA",
        hostedurl:"https://www.cxo2conf.com/",
        eventText:"Mar 04-06, 2025",
        eventImg:"https://s3.amazonaws.com/health-conference/the2conf/website-logo/cxo_logo.png",
        evDate:"Dec 03-05, 2024",
        cardText:"The CXO 2.0 Conference is a leading business leadership summit that brings together top executives to build influential partnerships and explore innovative strategies. It’s where the brightest minds unite to take charge of shaping tomorrow’s business landscape."
    },
    {
        id:2,
        eventName:"Dubai | USA",
        hostedurl:"https://www.education2conf.com/",
        eventText:"Mar 04-06, 2025",
        eventImg:"https://s3.amazonaws.com/health-conference/the2conf/website-logo/education_logo.png",
        evDate:"Dec 03-05, 2024",
        cardText:"Experience the latest innovations in learning and teaching at the Education 2.0 Conference — a dynamic gathering where connections flourish, visionary ideas are exchanged, and transformative solutions in education take center stage, inspiring the future of learning worldwide."
    },
  
    {
        id:3,
        eventName:"Dubai | USA",
        hostedurl:"https://www.health2conf.com/",
        eventText:"Mar 04-06, 2025",
        eventImg:"https://s3.amazonaws.com/health-conference/the2conf/website-logo/health_logo.png",
        evDate:"Dec 03-05, 2024",
        cardText:"The Health 2.0 Conference is a global 3-day event where healthcare experts come together to network, exchange groundbreaking ideas, and build transformative connections. It’s a platform to discuss the future of healthcare while driving innovative and collaborative solutions."
    },
    {
        id:4,
        eventName:"Dubai | USA",
        hostedurl:"#",
        eventText:"Mar 04-06, 2025",
        eventImg:"https://s3.amazonaws.com/health-conference/the2conf/website-logo/fuled_logo.png",
        evDate:"Dec 03-05, 2024",
        btntext:"",
        cardText:"At this influential marketing tech event, leaders from marketing and technology gather to redefine industry standards through cutting-edge innovations and collaborative strategies. It’s the epicenter of ideas that are shaping the future of both fields. "
    },



    
];

const BannerDataEdu =[


    {
        id:0,
        title:"Education",
        bannerimg:"https://s3.amazonaws.com/health-conference/the2conf/banner_health.png",
        titlename:"Home / Education"
    },
  

];
const BannerDataHeth =[

    {
        id:0,
        title:"Healthcare",
        bannerimg:"https://s3.amazonaws.com/health-conference/the2conf/banner_health.png",
        titlename:"Home / Healthcare"
    },

 

];
const BannerDataMark =[


    {
        id:0,
        title:"Marketing & Technology",
        bannerimg:"https://s3.amazonaws.com/health-conference/the2conf/banner_health.png",
        titlename:"Home/Marketing & Technology"
    }


];
const BannerDataCxo =[


    {
        id:0,
        title:"Business And Leadership",
        bannerimg:"https://s3.amazonaws.com/health-conference/the2conf/banner_health.png",
        titlename:"Home / Business And Leadership"
    }

];
const BannerDataInternet =[


    {
        id:0,
        title:"Technology",
        bannerimg:"https://s3.amazonaws.com/health-conference/the2conf/banner_health.png",
        titlename:"Home / Technology"
    }

];
const SectorsContent =[
    {
        id:1,
        sctorsTitle:"Marketing & Technology",
        sctorsImg:"https://s3.amazonaws.com/health-conference/the2conf/sector-fuled/across-sectors.png",
        sctorsUrl:"/fueld",
        sctorsContent:"The digital landscape is evolving at lightning speed, introducing fresh ways for businesses to engage with audiences and optimize their operations. From AI-driven marketing strategies to cutting-edge digital tools, businesses leverage data analytics, automation, and personalized content to enhance customer engagement and drive growth. As marketing and technology continue to merge, our marketing tech conference offers a top platform for leaders to keep up with these changes and succeed in today’s competitive world"
    },
    {
        id:2,
        sctorsTitle:"Healthcare",
        sctorsImg:"https://s3.amazonaws.com/health-conference/the2conf/col12.jpg",
        sctorsUrl:"/Healthcare",
        sctorsContent:" In the field of healthcare, significant advancements and innovative breakthroughs are reshaping the landscape of healing and patient care. Amidst this, we offer you the opportunity to step into the realm of AI-powered diagnostics, personalized treatments, and telemedicine, and more healthcare trends. Meet leading medical professionals at Health 2.0 Conference, and embark on a remarkable journey through the industry of healthcare, discovering the immense possibilities and real-world impact of its rapid advancements."
    },
    {
        id:3,
        sctorsTitle:"Business Leadership",
        sctorsImg:"https://s3.amazonaws.com/health-conference/the2conf/col13.jpg",
        sctorsUrl:"/business-and-leadership",
        sctorsContent:" Leadership takes center stage as we delve into the evolving domain of business. For senior business leaders, it has become imminent to explore the world of innovative trends that redefine the path to success in today's fast-paced markets. Enter CXO 2.0 Conference, where business leaders can discover their maximum leadership potential, empowering organizations to adapt swiftly to changing business demands. Immerse yourself in the art of purpose-driven leadership, learn sustainable core values and develop professionally and personally with our global business conference for directors and CEOs from around the world."
    },
    {
        id:4,
        sctorsTitle:"Education",
        sctorsImg:"https://s3.amazonaws.com/health-conference/the2conf/col15.jpg",
        sctorsUrl:"/education",
        sctorsContent:" Education is undergoing a transformation driven by innovative trends that cater to diverse learning needs. Online learning platforms, gamified education, and virtual classrooms are becoming increasingly prevalent, providing flexible and interactive learning experiences. With so much going on, Education 2.0 Conference emerges as a networking and learning platform to equip educators and enthusiasts with the latest trends and learning strategies, and creating a global space for collaboration and ideation."
    }
    
];
const GallaryContent =[
    {
        id:1,
        GallaryTitle:"Education 2.0 Conference ",
        GallaryImg:"https://s3.amazonaws.com/health-conference/the2conf/gallery-page/Images_Education.png",
        GallaryImgAlt:"img1",
        GallaryUrl:"https://www.education2conf.com/event-gallery",
        GallaryContent:"Dive into the vibrant moments from our 3-day Education 2.0 Conference! Explore how our visionary leaders transformed these days into a whirlwind of insights and inspiration. Every snapshot captures the spirit of progress and collaboration. Click below to join us on this visual journey! "
    },
    {
        id:2,
        GallaryTitle:"Health 2.0 Conference",
        GallaryImg:"https://s3.amazonaws.com/health-conference/the2conf/gallery-page/Images_Health.png",
        GallaryImgAlt:"img2",
        GallaryUrl:"https://www.health2conf.com/event-gallery",
        GallaryContent:"As we gear up for our upcoming editions in the USA and Dubai, let's take a moment to reminisce. Dive into the archives of our past events, where trailblazing speakers unveiled the latest breakthroughs in healthcare. Revel in memories of vibrant discussions and building connections with fellow visionaries. Welcome to the gallery of innovation and inspiration!"
    },
    {
        id:3,
        GallaryTitle:"CXO 2.0 Conference",
        GallaryImg:"https://s3.amazonaws.com/health-conference/the2conf/gallery-page/Images_CXO.png",
        GallaryImgAlt:"img5",
        GallaryUrl:"https://www.cxo2conf.com/",
        GallaryContent:"The CXO 2.0 Conference is a premier event for CXOs from all industries to learn from the best and brightest, network with peers, discover new technologies and trends, and get inspired to take their businesses to the next level. Want to know what goes on during our events? Check out below!"
    },
    {
        id:4,
        GallaryTitle:"Marketing & Technology Conference",
        GallaryImg:"https://s3.amazonaws.com/health-conference/the2conf/gallery-page/Images_Marketing.png",
        GallaryImgAlt:"img4",
        GallaryUrl:"",
        GallaryContent:"Our marketing tech conference is a premier 3-day gathering that brings together the top leaders from the marketing and technology sectors under one roof to discuss the future of these dynamic industries. The past marketing and tech events have witnessed attendees engaging in thought-provoking sessions, developing valuable connections, and exploring collaboration opportunities that drive innovation and growth."
    },
];
const BlogsbannerData = [
    {
        id:0,
        title:"Blogs",
        bannerimg:"https://s3.amazonaws.com/health-conference/the2conf/blog-page/banner-blog.png",
        titlename:"Talk with an advisor to create a customized inbound marketing program that blends proven strategy with tactical instruction and guarantees results.."
    }
];
const SingleBlogPageData = [
    {
        id:0,
        title:"",
        bannerimg:"https://s3.amazonaws.com/health-conference/the2conf/blog-page/single-page-banner.png",
        titlename:""
    }
];
const MarketingGData = [
    {
        id:0,
        title1:"Elevating Minds In Marketing",
        title2:"And Technology For Success!",
        SpeakerImg:"https://s3.amazonaws.com/health-conference/the2conf/sector-fuled/speaker_fueld.png",
        SpeakerAbout:"In the ever-evolving world of marketing and technology, innovation and data-driven strategies are transforming how brands connect with their audiences. Businesses today harness digital platforms like social media, content marketing, and influencer partnerships to create impactful, personalized experiences. Trends such as ad scam prevention and customer-centric approaches are key to building lasting relationships and staying competitive.",
        SpeakerAbout2:"The marketing tech conference is a hub of innovation, offering a unique opportunity to connect in person with industry leaders and pioneers. The event encourages face-to-face engagement, building trust and sparking creativity in ways that drive meaningful connections. Join us at our summit to immerse yourself in a global community of innovators shaping the future of marketing strategies and technological advancements. Experience the power of collaboration and fresh perspectives that can inspire your next big move in marketing and technology."
    }
];
const InternetGData = [
    {
        id:0,
        title1:"Enabling Technology And IT",
        title2:"Innovation On A Global Scale",
        SpeakerImg:"https://s3.amazonaws.com/health-conference/the2conf/Technology-image/Internet-Sircle_image_Marge.png",
        SpeakerAbout:"The arena of technology and the Internet is an ever-evolving landscape, constantly pushing the boundaries of innovation and reshaping the way we interact with the world. From groundbreaking advancements in artificial intelligence and data analytics to the integration of Internet of Things (IoT) devices and blockchain technology, the tech industry continues to revolutionize various sectors, fueling progress and transformation.",

        SpeakerAbout2:"Amidst this, Internet 2.0 Conference stands as a beacon of innovation and collaboration, bringing together tech leaders and professionals from across the globe. Through interactive sessions, thought-provoking discussions, and networking opportunities, the conference serves as a platform to exchange ideas, explore emerging trends, and forge partnerships that drive technological advancements. United under a common vision, attendees share their expertise, leverage collective insights, and shape the future of technology and IT through impactful collaborations."
    }
];
const EducationGData = [
    {
        id:0,
        title1:"Empowering Minds",
        title2:"To Shape The Future Of Education",
        SpeakerImg:"https://s3.amazonaws.com/health-conference/the2conf/Education-image/Educaion-Sircle_image_Marge.png",
        SpeakerAbout:"The education sector is undergoing a profound transformation today. Advancements in technology and digital learning have revolutionized the way knowledge is imparted, making education more accessible and inclusive. Personalized learning approaches cater to diverse student needs, while online platforms connect learners from around the globe. Education is no longer confined to traditional classrooms, as virtual learning environments empower students to learn at their own pace.",
        SpeakerAbout2:"As the education sector continues to evolve, Education 2.0 Conference provides a crucial platform for educators, policymakers, and stakeholders to explore emerging trends, exchange ideas, and collectively shape the future of education."
    }
];
const CxoGData = [
    {
        id:0,
        title1:"Inspiring Business",
        title2:"Leaders Towards Professional Excellence",
        SpeakerImg:"https://s3.amazonaws.com/health-conference/the2conf/Cxo-image/CXO-Sircle_image_Marge.png",
        SpeakerAbout:"In the world of business, effective leadership is the cornerstone of success. Leaders who inspire and empower their workforce create a culture of innovation and excellence, propelling the organization to new heights. Successful leaders embody a unique blend of vision, adaptability, and emotional intelligence, guiding their teams towards shared goals. They foster a collaborative and inclusive environment that encourages creativity and problem-solving.",
        SpeakerAbout2:"Encapsulating such business trends and enhancing business leadership among CEOs and senior leaders, CXO 2.0 Conference emerges as the leading professional and personal development global platform that unites renowned business leaders to innovate and collaborate on upcoming trends in the field, paving the way for business excellence."
    }
];
const HealthGData = [
    {
        id:0,
        title1:"Uniting Healthcare",
        title2:"Professionals For A Better  Tomorrow.",
        SpeakerImg:"https://s3.amazonaws.com/health-conference/the2conf/Health-image/ellips-side.png",
        SpeakerAbout:"The world of healthcare is constantly evolving, marked by dynamic changes that have revolutionized patient care and medical practices. It is a realm where pioneering advancements and revolutionary concepts thrive, driven by compassion and cutting-edge technology. From precision medicine and telemedicine breakthroughs to the adoption of digital health and AI-driven diagnostics, the healthcare industry continues to shape the future of medicine, prioritizing better health outcomes and dedicated efforts.",
        SpeakerAbout2:"In this ever-shifting landscape, the Health 2.0 Conference emerges as a transformative catalyst, bringing together visionaries, healthcare pioneers, and dedicated professionals. Together, we pave the way for a healthier world, embracing innovation and fostering collaboration to elevate patient care and shape the future of healthcare."
    }
];
const MarketingConfAbout = [
    {
        id:0,
        title1:"About Our",
        title2:" Marketing & Technology Conference",
        SpeakerImg:"https://s3.amazonaws.com/health-conference/the2conf/sector-fuled/about-conference.png",
        SpeakerAbout:"This marketing tech conference brings together top experts, executives, and professionals to explore the latest advancements and trends in marketing and technology. With an extensive network of organizations and attendees from leading brands and multinational corporations, we are committed to advancing creativity and expertise in these fields.",
        SpeakerAbout2:"The marketing tech conference is a hub of innovation, offering a unique opportunity to connect in person with industry leaders and pioneers. The event encourages face-to-face engagement, building trust and sparking creativity in ways that drive meaningful connections. Join us at our summit to immerse yourself in a global community of innovators shaping the future of marketing strategies and technological advancements. Experience the power of collaboration and fresh perspectives that can inspire your next big move in marketing and technology. ",
        ConfWebsiteUrl:"https://www.fueldconf.com/"
    }
];
const InternetConfAbout = [
    {
        id:0,
        title1:"About The",
        title2:"Internet 2.0 Conference",
        SpeakerImg:"https://s3.amazonaws.com/health-conference/the2conf/Technology-image/about_img.png",
        SpeakerAbout:"Internet 2.0 Conference is a premier three-day event that gathers global tech leaders on a single stage, fuelling tech and IT revolution. Our goal is to break away from the mundane routine of tech events, transporting its attendees into a world of electrifying energy and innovative possibilities. From the iconic Las Vegas, with its dazzling lights and non-stop entertainment, to the playground of Dubai, with its breathtaking skyscrapers and luxurious extravagance, we set the stage for boundless inspiration and networking opportunities.",

        SpeakerAbout2:"",
        ConfWebsiteUrl:"https://www.internet2conf.com/"
    }
];
const EducationConfAbout = [
    {
        id:0,
        title1:"About The",
        title2:"Education 2.0 Conference",
        SpeakerImg:"https://s3.amazonaws.com/health-conference/the2conf/Education-image/about_img.png",
        SpeakerAbout:"The Education 2. 0 Conference is a platform where academia and business leaders from the education industry converge in a unique celebration of knowledge and innovation. Unlike conventional education events, we go beyond merely discussing theories and research papers — we are passionate about bridging the gap between theory and practice. Our conference is renowned for seamlessly connecting leading minds from top institutions and industry giants. And that's not all; we pride ourselves on providing world-class hospitality, creating an immersive and enjoyable experience for our attendees.",

        SpeakerAbout2:"",
        ConfWebsiteUrl:"https://www.education2conf.com/"
    }
];
const CxoConfAbout = [
    {
        id:0,
        title1:"About The",
        title2:"CXO 2.0 Conference",
        SpeakerImg:"https://s3.amazonaws.com/health-conference/the2conf/Cxo-image/about_img.png",
        SpeakerAbout:"CXO 2.0 Conference is a renowned business leaders’ conference where visionary leaders and top-notch professionals from renowned brands and corporations converge for a transformative professional development journey. With a vibrant community of Fortune 500 organizations and promising start-ups, CXO 2.0 Conference is your gateway to innovation and cutting-edge insights in the realm of business. Join us for CXO 2.0 Conference in 2024, and embark on a journey of inspiration, collaboration, and unparalleled opportunities to shape the future of business.",

        SpeakerAbout2:"",
        ConfWebsiteUrl:"https://www.cxo2conf.com/"
    }
];
const HealthConfAbout = [
    {
        id:0,
        title1:"About The",
        title2:"Health 2.0 Conference",
        SpeakerImg:"https://s3.amazonaws.com/health-conference/the2conf/Health-image/about_img.png",
        SpeakerAbout:"Health 2.0 Conference is a transformative 3-day event that gathers leading healthcare professionals to explore the latest innovations and advancements in healthcare, holistic wellness, and medicine. With a focus on digital health, telemedicine, precision medicine, AI-driven diagnostics, and more, the conference offers a unique platform for knowledge exchange, networking, and collaboration. Through engaging discussions and speaker sessions, Health 2.0 Conference drives the conversation towards elevating patient care and revolutionizing the healthcare industry.",
        SpeakerAbout2:"",
        ConfWebsiteUrl:"https://www.health2conf.com/"
    }
];
const GalleryMarketing = [
    {
        id:0,
        GalleryImg:"https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/img-1.png",
         
    },
    {
        id:1,
        GalleryImg:"https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/img-2.png",
         
    },
    {
        id:2,
        GalleryImg:"https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/img-3.png",
         
    },
    {
        id:3,
        GalleryImg:"https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/img-4.png",
         
    },
    {
        id:4,
        GalleryImg:"https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/img-5.png",
         
    },
    {
        id:5,
        GalleryImg:"https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/img-6.png",
         
    },
    {
        id:6,
        GalleryImg:"https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/img-7.png",
         
    },
    {
        id:7,
        GalleryImg:"https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/img-8.png",
         
    },
    {
        id:8,
        GalleryImg:"https://s3.amazonaws.com/health-conference/the2conf/Marketing-image/img-9.png",
         
    },
];
export  default CardData;
export{BannerDataEdu,BannerDataHeth,BannerDataMark,BannerDataCxo,BannerDataInternet,SectorsContent,GallaryContent,BlogsbannerData,MarketingGData,InternetGData,EducationGData,CxoGData,HealthGData,MarketingConfAbout,InternetConfAbout,EducationConfAbout,CxoConfAbout,HealthConfAbout,SingleBlogPageData,GalleryMarketing};
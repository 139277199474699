import React from 'react'
import { useEffect } from 'react';
import { Helmet } from "react-helmet";
import AttendeeReviewsGallery from '../Components/AttendeeReviewsGallery';
import ReviewCards from '../Components/ReviewCards';
import WhoAttendedLogos from '../Components/WhoAttendedLogos';
const AttendeeReviews = () => {
    useEffect(() => {
        document.body.className = 'gallery_gallery';
        return () => {
          document.body.className = '';
        };
      }, []);
  return (
    <>
       <Helmet>
<meta charset="utf-8" />
<title>Attendee Reviews | The 2.0 Conferences </title>
<base href="/" />
<meta name="robots" content="index"></meta>
<meta name="description" content=" Read reviews from attendees of The 2.0 Conferences. Discover how our legacy of industry events offers unmatched learning and networking opportunities.
" />
<meta property="og:title" content="Attendee Reviews | The 2.0 Conferences " />

</Helmet>
  <section className="across_sectors_banner attendee-reviews_banner">
    <figure className="gallery_figure">
      <img
        src="https://the2conf.com/static/media/banner_health.406a28d1c6ea68d56606.png"
        alt="img"
        width="100%"
      />
      <figcaption className="gallery_banner_caption">
        <div className="container text-center">
          <div className="across_sectors_content">
            <h1 className="across_sectors_heading">
              Attendee Reviews 
            </h1>
            <p>
            Check out what our delegates loved the most!
            </p>
          </div>
        </div>
      </figcaption>
    </figure>
  </section>
  {/* =========================== */}
  <section className="confrence_community text-center">
        <div className="container">
          <h3>Empowering Voices From 
         <span> The Frontlines Of Innovation</span></h3>
         
          <p>The 2.0 Conferences are pivotal events that unite leading minds from various industries to spark innovation and promote collaboration. Held in dynamic cities around the globe, these conferences offer a unique platform for professionals to engage in transformative discussions and network with peers. Check out the reviews from past participants to discover how The 2.0 Conferences have influenced industry leaders and contributed to groundbreaking advancements. We look forward to continuing this journey with even more dynamic sessions in the years to come! </p>
        </div>
      </section>
  <ReviewCards/>
  <WhoAttendedLogos/>
  <AttendeeReviewsGallery/>
   <section className="join_us_box">
    <div className="container">
      <div className="join_box">

        <div className="row">
          <div className="col-md-6 col-12">
            <img src="https://s3.amazonaws.com/health-conference/the2conf/attendee-img/hand-sk.png" alt=""  width="100%"/>
          </div>
          <div className="col-md-6 col-12">
             <div className='join_text'>
             <h3>Join Us Now!</h3>
             <p>
             Attention innovators, leaders, and visionaries—be part of the transformative experience at <a href="https://www.the2conf.com/" target="_blank" rel="noopener noreferrer">The 2.0 Conferences! </a> Don't miss your chance to be at the forefront of change!

             </p>
             </div>
          </div>
        </div>
      </div>
    </div>
   </section>
    </>
  )
}

export default AttendeeReviews

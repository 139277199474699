import React from "react";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  const Headerstyle = {
    background: "rgb(28 29 63)", 
    position: "fixed",
    zIndex: "1030",
    width: "100%",
    lineHeight: 1,
    padding:" 8px 0", 
    display: "flex",
    alignItems: "center"
  };
  const marqeText = {
    fontSize: "16px",
    lineHeight: "1.2",
    textDecoration: "none",
    color: "#fff",
    fontWeight:" 500",
}
  return (
    <>
      <header className="main_header">
      <div style={Headerstyle}> 
                 <marquee behavior="scroll" direction="left">

                    <Link to="https://www.ihg.com/intercontinental/hotels/us/en/find-hotels/select-roomrate?fromRedirect=true&qSrt=sBR&qIta=99801505&icdv=99801505&qSlH=DXBHB&qCiD=02&qCiMy=112024&qCoD=05&qCoMy=112024&qGrpCd=V4K&setPMCookies=true&qSHBrC=IC&qDest=PO%2045777,%20Dubai,%20AE&srb_u=1&qChAge=&qRmFltr=" target="_blank" style={marqeText} >
                        <span>Stay at InterContinental, Dubai Festival City {"– "} only 898++ AED per night! (exclusive to attendees)</span>
                    </Link>
                    
                </marquee>
            </div>
      <nav className="main_navbar navbar navbar-expand-lg navbar-light sticky-top" style={{paddingTop:"5rem"}}>
  <div className="container">
    <Link exact to="/" aria-current="page" className="navbar-brand">
      <img src="https://s3.amazonaws.com/health-conference/the2conf/the2-conf-color-logo.svg" alt="logo" className="logo" />
    </Link>
    <button
      className="navbar-toggler w-auto"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div
      className="collapse navbar-collapse"
      id="navbarSupportedContent"
    >
    <div className="mx-right main_menu_wrapper navbar-nav">
      <ul className=" main_menu navbar-nav  mb-2 mb-lg-0">
        <li className="nav-item">
          <NavLink to="/our-gallery" className="nav-link ">
            our gallery
          </NavLink>
        </li>
        

        <li className="nav-item">
          <NavLink to="/attendee-reviews" className="nav-link ">
            attendee reviews
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/sectors"
            className="nav-link"
          >
            Sectors
          </NavLink>
        </li>
        
      </ul>
      </div>
    </div>
  </div>
</nav>
      </header>
    </>
  );
};

export default Header;